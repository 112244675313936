import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { connectMQTT, connectOptions, connectURL, createHeartbeatConfig } from '../helpers/mqtt';
import { MqttClient } from 'mqtt';
import { heartbeat, updateHeartbeatMessage, updateHeartbeatTime, getHeartbeats, getStatus } from '../helpers/heartbeat';
import OnlineIndicator from "./OnlineIndicator";
import MainMap from './Map';


const defaultData = [
  {
    serialId: 'ttc02',
    name: 'Zhero van 1',
    isOnline: false,
    lat: -1, //for testing - should reset to 0 or valid 
    lng: -1,
    vSetpoint: -1,
    vOutput: -1,
    vDevice: -1

  },
  {
    serialId: 'ttc03',
    name: 'Zhero van 2',
    isOnline: false,
    lat: -1,
    lng: -1,
    vSetpoint: -1,
    vOutput: -1,
    vDevice: -1
  },
  {
    serialId: 'ttc01',
    name: 'Office test rig',
    isOnline: false,
    lat: 0,
    lng: 0,
    vSetpoint: -1,
    vOutput: -1,
    vDevice: -1
  }
]

interface device {
  serialId: string,
  name: string,
  isOnline: boolean,
  lat: number,
  lng: number,
  vSetpoint: number,
  vOutput: number,
  vDevice: number
}

export default function List() {
  const [data, setData] = React.useState<device[]>(defaultData)
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [message, setMessage] = React.useState<string | false>(false);
  const [client, setClient] = React.useState<MqttClient | false>(false);
  const [heartbeats, setHeartbeats] = React.useState<heartbeat[]>(getHeartbeats(data));
  const [seconds, setSeconds] = React.useState(0);

  const getDeviceList = (data: device[]) => {
    return data.map(device=>{
      return device.serialId;
    })
  }

  async function connectClient () {
		const messageTopics = createHeartbeatConfig(getDeviceList(data));
		const propsMQTT = {
			url: connectURL,
			options: connectOptions(),
			topics: messageTopics,
			setMessage: setMessage
		};
		return connectMQTT(propsMQTT).then((clientBack) => {
			console.log('MQTT connected');
			return clientBack;
		});
	}

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded? panel : false);
  };

  const updateData = (data: device[], message: any) => {
    const tempData = [...data]
    let serialID = '';
    let topic = '';
    let geoLocation: any = { lat: 0, lng: 0 };
    let voltage: any = {set: 0, out: 0, dev: 0};
    let voltageUpdateFlag: boolean = false;
    if (message.topic) {
      serialID = message.topic.split('/')[1];
      topic = message.topic.split('/')[3];
      geoLocation = message.message.geo_position;
      if (topic === "psu"){
        voltage.set = message.message.payload.value.voltageSetpoint;
        voltage.out = message.message.payload.value.voltageOutput;
        voltage.dev = message.message.payload.value.voltage;
        voltageUpdateFlag = true;
      }
    }
    tempData.map(device => {
      if (device.serialId === serialID) {
        device.lat = geoLocation.lat;
        device.lng = geoLocation.lon;
        if (voltageUpdateFlag){
          device.vSetpoint = voltage.set;
          device.vOutput = voltage.out;
          device.vDevice = voltage.dev;
          voltageUpdateFlag = false;
        }
       
      }
      device.isOnline = getStatus(device.serialId, heartbeats);
    })
    setData(tempData);
  }
  React.useEffect(()=>{
    connectClient().then((client)=> {
      setClient(client);
    })
  }, []);

  React.useEffect(() => {
		updateHeartbeatMessage(heartbeats, message, setHeartbeats);
    updateData(data, message)
	}, [message]);

  React.useEffect(() => {
		setTimeout(() => {
			setSeconds(seconds + 1);
			updateHeartbeatTime(heartbeats, message, setHeartbeats);
		}, 1000);
	}, [seconds]);

  return (
    <Box style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <Box>
          <MainMap data={data} />
        </Box>
        <Box>
        {data.map((device: any, i: number)=>{
          return (
              <Accordion expanded={expanded === i.toString()} onChange={handleChange(i.toString())}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <OnlineIndicator status={device.isOnline}/>
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {device.name}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>Serial ID: {device.serialId}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" sx={{marginLeft: "20px"}}>
                    <Typography fontWeight="bold">
                      Location
                    </Typography>
                    <Typography>
                      Latitude: {device.lat}
                    </Typography>
                    <Typography>
                      Longitude: {device.lng}
                    </Typography>
                    <Typography fontWeight="bold">
                      Voltage
                    </Typography>
                    <Typography>
                      V_Setpoint: {device.vSetpoint}
                    </Typography>
                    <Typography>
                      V_Output: {device.vOutput}
                    </Typography>
                     <Typography>
                      V_Device: {device.vDevice}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
          )
        })}
        </Box>
    </Box>
  );
}