import * as React from 'react';
import Button from '@mui/material/Button';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import MapGL, { Marker, NavigationControl, ScaleControl, Anchor } from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import van1 from '../resources/van_1.png';
import van2 from '../resources/van_2.png';
import vanTest from '../resources/van_ttc.png';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface props {
  data: {
    serialId: string,
    name: string,
    isOnline: boolean,
    lat: number,
    lng: number,
    vSetpoint: number,
    vOutput: number,
    vDevice: number
  }[]
}

const anchors: Anchor[] = ["bottom", "top", "right"];

export default function MainMap(props: props) {

  const [viewState, setViewState] = React.useState({
        longitude: -0.021114744177050414,
        latitude: 51.54464395713207,
        zoom: 10
  });

  const getImage = (sid: string): string => {
    switch (sid){
      case "ttc02":
        return van1;
      case "ttc03":
        return van2;
      case "ttc01":
        return vanTest;
    }
    return van1;

  }

  const centreMap = () => {
    setViewState({
        longitude: -0.021114744177050414,
        latitude: 51.54464395713207,
        zoom: 10,
    })
  }

  const returnMarkers = (): JSX.Element[] => {
    const markers: JSX.Element[] = [];
    props.data.map((point, i)=>{
      markers.push(
        <Marker 
            longitude={point.lng} 
            latitude={point.lat} 
            anchor={anchors[i]}
        >
            <img src={getImage(point.serialId)} height={40}/>
        </Marker>
      )
    })

    return markers;
  }

  return (
    <div>
      
    <MapGL
        {...viewState}
        style={{height: '40vh', marginBottom: 10, borderRadius: 5}}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX}
        onMove={evt => setViewState({
          longitude: evt.viewState.longitude,
          latitude: evt.viewState.latitude,
          zoom: evt.viewState.zoom
        })}
    >
        <NavigationControl position="bottom-right" />
        <ScaleControl />
        {returnMarkers()}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: 10,
          }}
        >
        <Button 
          onClick={centreMap} 
          style={{
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
            borderRadius: 50,
        }}>
          <CenterFocusStrongIcon color="info"/>
        </Button>
        </div>
    </MapGL>
      
    </div>
  );
}