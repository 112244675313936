import logo from '../src/resources/logo.jpeg';
import './App.css';
import List from '../src/components/List';
import Typography from '@mui/material/Typography';
import { isMobile } from "react-device-detect";



function App() {
  return (
    <div className="App">
      <header className="App-header">
       
        <a
          className="App-link"
          href="https://www.thetyrecollective.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{marginTop: "20px"}}
        >
           <img src={logo} className="App-logo" alt="logo" />
        </a>
        <div style={{margin: "20px"}}>
          <Typography variant='h4'>R&D Dashboard</Typography>
        </div>
      </header>
      <div className="Body" style={{flexDirection:"column", alignItems: "center"}}>
        <div className="leftPanel" style={isMobile?{width: "90%"}:{width: "20%"}}/>
        <div className="middlePanel" style={isMobile?{width: "90%", marginBottom: "20px"}:{width: "60%", marginBottom: "20px"}}>
          <List/>
        </div>
        <div className="rightPanel" style={isMobile?{width: "90%"}:{width: "20%"}}/>
      </div>
    </div>
  );
}

export default App;
