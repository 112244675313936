import * as React from 'react';
import Wifi from '@mui/icons-material/Wifi';
import WifiOff from '@mui/icons-material/WifiOff';
import Box from '@mui/material/Box';

interface props {
    status: boolean
}

export default function OnlineIndicator(props: props ) {
    const returnIcon = () => {
        if (props.status){
            return <Wifi/>
        } 
        return <WifiOff/>
    }
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{backgroundColor: props.status?"#37D67A":"#F47373", height: 50, width: 50, borderRadius: 100}}>
            {returnIcon()}
        </Box>
    );
}