import mqtt from 'mqtt';

interface IConnectMQTT {
	url: string;
	options: {
		port: number;
		clientId: string;
		username: string;
		password: string;
	};
	topics: any;
	setMessage: any;
}


export async function connectMQTT (props: IConnectMQTT) {
	const { url, options, topics, setMessage } = props;
	const client = mqtt.connect(url, options);
	if (topics) {
		client.subscribe(topics, (err, granted) => {
			if (granted) {
				granted.map((topic) => {
					console.log(`Subscribed to topic: ${JSON.stringify(topic)}`);
					return null;
				});
			}
			client.on('message', (topic, message) => {
				try {
					if (message !== undefined) {
						setMessage({ topic: topic, message: JSON.parse(message.toString()) });
						// console.log(topic, JSON.parse(message.toString()));
					}
				} catch (err) {
					console.log(err);
					if (message) {
						console.log('Message: ' + message.toString());
					}
				}
			});
		});
	}
	return client;
}

export const connectURL = 'wss://mqtt.e-nano.io:8083';

export function connectOptions () {
	const options = {
		port: 8083,
		clientId: `enano-client-${Date.now()}`,
		username: 'rayercat',
		password: 'blauWis5670101'
	};
	return options;
}

// export function createMessageConfig (robotID: string) {
// 	const psu = '/' + robotID + '/sensor/psu/ttc';

// 	const topicsObj = {
// 		[psu]: {qos: 0}
// 	};

// 	return topicsObj;
// }

export function createHeartbeatConfig (robotIds: string[]) {
	const topicArr: any = [];
	robotIds.forEach((robotId) => {
		const heartbeat = `/${robotId}/status/heartbeat/alive`;
		topicArr.push(heartbeat);
		const psu = `/${robotId}/sensor/psu/ttc`;
		topicArr.push(psu);
	});
	return topicArr;
}

